<template>
    <div class="dealings_box1">
        <div class="tab_title">
            <i class="iconfont icon-dingdan3"></i>
            往来记录
            <button @click="onAdd" class="cancel" v-show="isComp">新增往来</button>
        </div>
        <div class="dealings_his">
            <div class="item" v-for="item in dealingsList" :key="item.id">
                <div>
                    <p><span>往来标题</span>{{ item.intercourseTitle || '- -' }}</p>
                    <p><span>跟进人</span>{{ item.adminName || '- -' }}</p>
                    <p><span>往来时间</span>{{ item.intercourseTime || '- -' }}</p>
                    <p><span>联系方式</span>{{ $tableDataHandle.contactWay(item) }}</p>
                    <button @click="onAddDesc(item.id)" class="adddesc">
                        + 添加说明
                    </button>
                </div>
                <div class="con">
                    <span>往来内容</span>
                    <p>{{ item.intercourseContent }}</p>
                </div>
                <div v-for="itm in item.describeList" :key="itm.id" style="font-weight: 600">
                    <span>往来说明</span>{{ itm.describeContent || '- -' }}
                </div>
            </div>
        </div>
        <!-- 往来添加说明 -->
        <el-dialog
            :visible.sync="dialog"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="dialog"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    添加说明
                </div>
            </template>
            <div class="dialog_info">
                <el-form label-width="70px" label-position="left" size="small">
                    <el-form-item label="说明内容" required>
                        <el-input
                            type="textarea"
                            rows="6"
                            placeholder="请输入说明内容"
                            v-model="describeContent"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>

            <div slot="footer" class="dialog_footer">
                <el-button @click="handleClose" round size="mini">取 消</el-button>
                <el-button
                    type="primary"
                    @click="addExplain"
                    round
                    size="mini"
                    style="border-color: #2370eb; background: #2370eb"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
        <!-- 添加往来 -->
        <el-dialog
            :visible.sync="drawerDealing"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="clue_assist"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    添加往来
                </div>
            </template>
            <div>
                <AddDealing ref="adddealing" @close="handleClose" />
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { intercourseAdd } from '@/api/clue/meClue';
import AddDealing from './addDealings.vue';
import { clueDealingList } from '@/api/clue/depClue.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            dealingsList: [],
            dialog: false,
            describeContent: '',
            drawerDealing: false,
            adminId: sessionStorage.getItem('adminId'),
            isComp: true,
        };
    },
    components: {
        AddDealing,
    },
    methods: {
        getData() {
            this.dealingsList = data;
            let data = {
                param: {
                    lineId: this.$route.query.id,
                },
            };
            clueDealingList(data).then((res) => {
                if (res.code == 200) {
                    this.dealingsList = res.data.list;
                }
            });
            if (this.$route.query.isComp) {
                this.isComp = false;
            }
        },

        onAdd() {
            this.drawerDealing = true;
        },
        onAddDesc(id) {
            this.dialog = true;
            this.describeId = id;
        },
        addExplain() {
            if (!this.describeContent) {
                return this.$message.error('请输入说明');
            }
            let data = {
                param: { describeContent: this.describeContent, intercourseId: this.describeId },
            };
            intercourseAdd(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('添加成功');
                    this.handleClose();
                    this.describeContent = '';
                }
            });
        },
        handleClose() {
            this.dialog = false;
            this.drawerDealing = false;
            this.getData();
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
.dealings_box1 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-top: 10px;
    background-color: #fff;
    border-radius: 4px;
    .tab_title {
        height: 48px;
        line-height: 48px;
        border-bottom: 1px solid #e5e5e5;
        font-size: 12px;
        padding-left: 8px;
        i {
            color: #2370eb;
            vertical-align: -1px;
        }
        button {
            font-size: 12px;
            padding: 0 7px 0 12px;
            height: 24px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #2370eb;
            cursor: pointer;
            color: #2370eb;
            margin-right: 16px;
            float: right;
            margin-top: 12px;
        }
    }
    .dealings_his {
        flex: 1;
        overflow-y: auto;
        .item {
            border-bottom: 1px dashed #e5e5e5;
            padding: 16px 24px;
            font-size: 14px;
            > div:first-child {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                position: relative;
                p {
                    color: #333;
                    font-weight: 600;
                }
                .adddesc {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 96px;
                    height: 24px;
                    background: #ffffff;
                    border-radius: 2px;
                    border: 1px solid #f06f1e;
                    color: #f06f1e;
                }
            }
            div {
                span {
                    margin-right: 15px;
                    color: #666;
                    font-weight: normal;
                }
            }
            div + div {
                margin-top: 12px;
            }
            .con {
                display: flex;
                align-items: center;
                p {
                    padding: 6px;
                    font-weight: 600;
                    color: #333;
                    margin-left: -6px;
                    background: #f0f4ff;
                    border-radius: 4px;
                }
            }
        }
    }
}
.dialog_info {
    width: 80%;
    margin: 25px auto 150px;
    .info {
        font-size: 14px;
        margin-bottom: 16px;
        text-align: left;
        label {
            display: inline-block;
            width: 65px;
            font-weight: bold;
            color: #333;
            margin-left: 2px;
        }
    }
}
/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;
}
</style>
