<template>
    <div class="cust_box">
        <div class="cuet">
            <p class="title">
                <span></span>
                基本信息
            </p>
            <el-form
                label-position="left"
                size="small"
                label-width="90px"
                style="display: flex; margin-left: 31px"
            >
                <div style="flex: 1">
                    <el-form-item label="客户类型">
                        {{ customerData.enterpriseType == 1 ? '企业' : '个人' }}
                    </el-form-item>
                    <el-form-item label="域名">
                        {{ customerData.companyDomain || '- -' }}
                    </el-form-item>
                    <el-form-item
                        :label="index == 0 ? '联系方式' : ''"
                        v-for="(
                            item, index
                        ) in customerData.customerMainContactList"
                    >
                        {{
                            $tableDataHandle.contactWay(item)
                        }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {{
                            item.contactContent
                        }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                            item.contactName
                        }}
                    </el-form-item>
                    <el-form-item label="地址">
                        {{ customerData.address || '- -' }}
                    </el-form-item>
                    <el-form-item label="联系备注">
                        {{ customerData.contactRemark || '- -' }}
                    </el-form-item>
                </div>
                <div style="flex: 1">
                    <el-form-item label="客户标签">
                        <div
                            class="label_box"
                            v-if="customerTagList.length > 0"
                        >
                            <span
                                v-for="item in customerTagList"
                                :key="item.id"
                                :class="item.category == 0 ? 'blue' : 'orange'"
                            >
                                {{ item.tagName }}</span
                            >
                        </div>
                        <div v-else>- -</div>
                    </el-form-item>
                    <el-form-item
                        :label="index == 0 ? '关联域名' : ''"
                        v-for="(item, index) in customerData.customerDomainList"
                    >
                        {{ item.domain }}
                    </el-form-item>
                    <el-form-item label="预计用户数">
                        {{ customerData.customerExpireTime || '- -' }}
                    </el-form-item>
                    <el-form-item label="预计年限">
                        {{ customerData.customerExpireTime || '- -' }}
                    </el-form-item>
                    <el-form-item label="所属行业">
                        {{ $tableDataHandle.industry(customerData) }}
                    </el-form-item>
                    <el-form-item label="备注">
                        {{ customerData.remark || '- -' }}
                    </el-form-item>
                </div>
                <div style="flex: 1">
                    <el-form-item label="客户名称">
                        {{ customerData.companyName || '- -' }}
                    </el-form-item>
                    <el-form-item label="原邮箱品牌">
                        {{ $tableDataHandle.primaryEmailBrand(customerData) }}
                    </el-form-item>
                    <el-form-item label="到期时间">
                        {{ customerData.customerExpireTime || '- -' }}
                    </el-form-item>
                    <el-form-item label="所在地">
                        {{
                            $address.address(
                                customerData.provinceId,
                                customerData.cityId
                            )
                        }}
                    </el-form-item>
                </div>
            </el-form>
        </div>

        <div class="cont">
            <p class="title"><span></span>联系人信息</p>
            <el-form
                label-position="left"
                size="small"
                label-width="90px"
                style="display: flex; width: 100%; flex-wrap: wrap"
            >
                <div
                    class="contact_info"
                    v-for="item in customerContactList"
                    :key="item.id"
                    style="width: 30%; margin-left: 31px; margin-bottom: 20px"
                >
                    <el-form-item label="联系人">
                        {{ item.contactName || '- -' }}
                    </el-form-item>
                    <el-form-item
                        label="联系方式"
                        v-for="itm in item.customerContactWayDOList"
                    >
                        {{
                            $tableDataHandle.contactWay(itm)
                        }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {{
                            itm.contactContent
                        }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                            item.contactName
                        }}
                    </el-form-item>
                </div>
            </el-form>
        </div>
    </div>
</template>
<script>
import { relationList } from '@/api/user/label.js';
import { contactList } from '@/api/user/ae/customer';
export default {
    data() {
        return {
            customerData: {},
            customerTagList: [],
            customerContactList: [],
            adminId: sessionStorage.getItem('adminId'),
        };
    },
    methods: {
        getData(data) {
            console.log(data);
            this.customerData = data;

            this.relationList(data.id);
            this.getContactList(data.id);
        },
        changeCusomerFold() {
            this.$emit('changeCusomerFold', false);
        },
        //获取联系人
        getContactList(id) {
            let data = {
                param: {
                    customerId: id,
                    adminId: sessionStorage.getItem('adminId'),
                },
                pageNum: 0,
                pageSize: 0,
            };
            contactList(data).then((res) => {
                console.log(res);
                this.customerContactList = res.data.list;
            });
        },
        // 获取所属标签
        relationList(id) {
            let data = {
                param: {
                    bandingTableType: 2,
                    bandingId: id,
                },
            };
            relationList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.customerTagList = res.data.list;
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.cust_box {
    width: 100%;
    height: 100%;
    .cuet {
        padding-bottom: 20px;
        border-bottom: 1px solid #e5e5e5;
    }
    .title {
        font-size: 14px;
        font-weight: 600;
        color: #333333;
        line-height: 20px;
        display: flex;
        align-items: center;
        padding: 16px 19px;
        span {
            display: block;
            width: 4px;
            height: 4px;
            background: #2370eb;
            border-radius: 50%;
            margin-right: 8px;
        }
    }
    .cont {
        width: 100%;
    }
}
</style>
